import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContentList from "../components/content-list/contentList";

import { getImage } from "../utils";

type DataWithDate = {
  date: string;
};

const ContentPage = (props: any) => {
  const { data } = props;
  const videosData = data.allYoutubeVideo.edges;
  const postsData = data.allMdx.edges;
  const videos = videosData.map(({ node }: any) => {
    return {
      type: "video",
      id: node.id,
      title: node.title,
      image: node.localThumbnail.childImageSharp.fluid,
      imageType: "",
      url: `https://www.youtube.com/watch?v=${node.videoId}`,
      description: node.description,
      date: node.publishedAt,
    };
  });
  const posts = postsData.map(({ node }: any) => {
    const postThumbnail =
      node.frontmatter.thumbnail != null ? node.frontmatter.thumbnail : node.frontmatter.videoThumbnail;
    const { image, imageType } = getImage(postThumbnail);
    return {
      type: "post",
      key: node.fields.slug,
      title: node.frontmatter.title,
      image: image,
      imageType: imageType,
      url: node.fields.slug,
      description: node.frontmatter.excerpt || node.excerpt,
      date: node.frontmatter.date,
      category: node.frontmatter.category,
      stripMdx: node.fields.stripMdx,
    };
  });

  const allResults = [...videos, ...posts].sort((a: DataWithDate, b: DataWithDate): any => {
    const date1: any = new Date(b.date);
    const date2: any = new Date(a.date);
    return date1 - date2;
  });

  return (
    <Layout header={false} footer={false}>
      <SEO title="Content Page" />
      <ContentList allResults={allResults} />
    </Layout>
  );
};

export default ContentPage;

export const pageQuery = graphql`
  query ContentPage {
    allYoutubeVideo(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          title
          description
          videoId
          publishedAt(formatString: "MMMM DD, YYYY")
          localThumbnail {
            childImageSharp {
              fluid(maxWidth: 297, maxHeight: 167, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { ne: "category" } }, fileAbsolutePath: { regex: "/blog/" } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
            stripMdx
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            videoThumbnail {
              publicURL
              extension
            }
            thumbnail {
              publicURL
              extension
              childImageSharp {
                fluid(maxWidth: 297, maxHeight: 167, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            category
          }
        }
      }
    }
  }
`;
